.filter-heading{
    padding-bottom:2rem;
    h3{
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 1;
        margin: 0 0 0.5rem;
        text-transform: none;
        font-family: "acumin-pro",Helvetica,Arial,sans-serif;
    }
    p{
        padding:1;
        a{
            color: #737373;
            font-size: .8rem;
            font-weight: 500;
            line-height: 2;
            text-decoration: none;
        }
    }
    @media screen and (max-width: 1024px) {
        background: #efefef;
        padding:1rem;
        h3{
            font-size: 1.6rem;
            margin: 0;
        }
        p{
            margin-bottom:0;
            a{
                line-height: 1;
            }
        }
    }

}
.filters-layout{

    h5{
        border-bottom: 1px solid #efefef;
        padding-bottom: 1rem;
        font-size: 1rem;
        font-weight: 700;
        font-family: "acumin-pro", Helvetica, Arial, sans-serif;
    }
    @media screen and (max-width: 1024px){
        background:#f9f9f9;
        border-bottom:3px solid #edecec;
        h5{
            display:none;
        }
    }
}
.filter {
    font-family: "acumin-pro",Helvetica,Arial,sans-serif;
    font-size: .8rem;
    margin: 0 0 1rem;


    ul{
        @media screen and (max-width: 1024px) {
            margin:0 0.6rem;
        }
        li{
            color:#666;
            display:flex;
            flex-wrap:wrap;
            align-items: center;
            font-size: .8rem;
            margin-bottom:0.2rem;
            cursor:pointer;
            a{
                color:#666;
                &:hover{
                    color:$red;
                }
            }
            &:hover{
                span{
                    color:$red;

                }
            }
            // @media screen and (max-width: 1024px){
            //     padding: 0 1rem;
            // }
        }
    }
    // i.fas.fa-times-circle,
    //     label {
    //         &:hover {
    //             cursor: pointer;
    //         }
    //     }

    //     label {
    //         font-size: 1rem;
    //         font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
    //         margin: 0;
    //         margin-left: 0.5rem;
    //     }
        // input[type="checkbox"] {
        //     border: 1px solid #e6e6e7;
        //     height: 1.2rem;
        //     margin-right: 0.75rem;
        //     width: 1.2rem;
        //     &:hover {
        //         cursor: pointer;
        //     }
        // }

    .filter-icon{
        cursor: pointer;
        svg{
            fill: #666;
            width: 3rem;
            margin-right:0.3rem;
            &:hover{
                fill:$red;
            }
        }
    }
    .filterSelected{
        svg{
            fill: $red !important;

        }
    }
    .filterChecked{
        .filter-checkbox{
            .fa-check{
                display:block !important;
            }
        }

    }
    .filter-checkbox{
        border: 1px solid #e6e6e7;
        height: 1.2rem;
        margin-right: 0.75rem;
        width: 1.2rem;
        .fa-check{
            display:none;
            color:$red;
            font-size:1rem;
        }

    }

}
.filter-dropdownbar{
    display:none;
    background:#333;
    color:#fff;
    padding:0.5rem 1rem;
    p{
        margin-bottom:0;
    }
    i{
        cursor: pointer;
        padding:0 0.5rem;
        transform:rotate(180deg);
        transition:  transform 0.6s;
    }
    .collapsed{
        i{
            transform:rotate(0deg) !important;
        }
    }
    .prod-layout-icon{
        color:rgb(153, 153, 153);
    }
    @media screen and (max-width: 1024px) {
        display:flex;
        justify-content: space-between;
    }
}
.prod-layout-select{
    color:#fff;
}
