

#prod-images {
    .hero {
        width: 100%;
        height: auto;
        background: transparent;
        .carousel-control-next,
        .carousel-control-prev /*, .carousel-indicators */ {
            filter: invert(100%);
        }
    }
    .carousel-item {
        text-align: center;
        width: 100%;
        img {
            max-width: 80%;
            display: inline-block;
            margin: 0 auto;
        }
        .carousel-caption {
            display: none;
        }
    }
}

#prod-details {
    h2 {
        margin: 0;
    }
    .description {
        font-weight: 500;
    }
    .sku {
        color: $red;
        font-size: 1.1rem;
        font-weight: 800;
        font-family: $font-family-sans-serif;
    }
    p,ul {
        font-family: $font-family-pip-main;
    }
    #product-buttons {
        display: flex;
        justify-content: space-around;
    }
}

#prod-details-bottom {
    border: 1px solid #ccc;
    li.nav-item {
        padding: .3rem .5rem;
        text-transform: uppercase;
        font-family: $font-family-pip-main;
        font-weight: normal;
        position: relative;

        a {
            color: #111;
            &:hover {
                text-decoration: none;
            }
        }
        &.active {
            background: #ccc;
            border-radius: 5px 5px 0 0;

        }
    }
}

