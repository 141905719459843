.breadcrumbs {
    font-family: $font-family-pip-main;
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    a {
        color: #333;
        &:hover {
            color: $red;
        }
    }
    span{
        padding: 0 0.3rem;
    }
}

#filters {
    background: #ccc;
    color: #333;
    padding: 2rem;
    h3 {
        font-family: $font-family-pip-main;
        font-weight: 700;
    }
    .filter-div {
        border-bottom: 1px solid #999;
        padding: 1em;
    }
}


