.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 260px));
    padding-top: 2rem;
    align-items: stretch;
    column-gap: 1.5rem;
    @media screen and (max-width: 1024px) {
        column-gap: 0;
    }
    @media screen and (max-width: 830px) {
        grid-template-columns: 49% 49%;
        column-gap: 0.8rem;
    }
    .product {
        max-width: 90%;
        border-bottom: 1px solid #ccc;
        transition: all 0.4s ease-out;
        @media screen and (max-width: 830px) {
            max-width: 100%;
        }
        &:hover {
            border-bottom: 2px solid #cd1b00;
        }
        .product-gallery{
            height:100%;
            >div{
                &:first-child{
                    display:flex;
                    flex-flow:column nowrap;
                }
            }

            .product-gallery-icon{
                margin-top:auto;
                @media screen and (max-width: 830px) {
                    display:none;
                }
            }

            .img-container {
                max-width: 250px;
                // min-height: 200px;
                // height: 200px;
                img {
                    max-height: 100%;
                    max-width: 100%;
                    object-fit: contain;
                }
            }
            .description {
                font-size: 1rem;
                min-height: 50px;
                font-weight: 100;
                font-family: "Acumin Pro", Helvetica, Arial, sans-serif;
            }
            .prod-id {
                color: #cd1b00;
                font-size: 1rem;
                font-weight: 600;
                margin-top: 0;
            }
            .prod-name {
                font-family: "acumin-pro", Helvetica, Arial, sans-serif;
                font-size: 1.1rem;
                font-weight: 600;
                line-height:1;
                margin: 1.5rem 0 0.4rem 0;
            }
            .specs{
                display:block;
                @media screen and (max-width: 743px) {
                    display:none;
                }
            }

        }

    }
}
.product-horizontal-layout{
    grid-template-columns: auto !important;
    column-gap: 0;
    .product {
        .product-gallery{
            >div{
                &:first-child{
                    flex-direction:row !important;
                }
            }

            .img-container {
                @media screen and (max-width: 743px) {
                    max-width:150px !important;
                }

            }
        }

    }
}


.icon-tall{
    svg{
        height: 30px;
    }
}
.icon-square{
    svg{
        width: 32px;
    }
}
.icon-wide{
    svg{
        width: 37px;
    }
}
.icon-medium {
    svg {
        width: 27px;
    }
}
.icon-small {
    svg {
        width: 22px;
    }
}
.icon-extra-wide{
    svg{
        width: 62px;
    }
}
.icon-text-small{
    font-size: .5rem;
}
#custom-logo-icon{
    display:none;
}
.product-single-icons{

    .icon-tall{
        svg{
            height: 35px;
        }
    }
    .icon-square{
        svg{
            width: 42px;
        }
    }
    .icon-wide{
        svg{
            width: 47px;
        }
    }
    .icon-medium {
        svg {
            width: 37px;
        }
    }
    .icon-small {
        svg {
            width: 32px;
        }
    }
    .icon-extra-wide{
        svg{
            width: 72px;
        }
    }
    .icon-text-small{
        font-size: .6rem;
    }
}

.product-icon-tooltip{
    position:relative;
    .prod-icon{
        margin-right:0.5rem;
        cursor: pointer;
        &:hover +.icon-tooltip{
            opacity:1;
            bottom:50px;
            visibility: visible;
        }
    }
    .icon-tooltip{
        position:absolute;
        width:200px;
        bottom:100px;
        left:-87px;
        background-color: #111;
        border: 2px solid #333;
        color: #fff;
        font-family: acumin-pro-condensed;
        font-weight: 600;
        line-height: 1.1;
        font-size:0.8rem;
        margin: 0 auto;
        opacity: 0;
        padding: 0.75rem 0.75rem 0.25rem 0.75rem;
        text-align: left;
        transition: all .2s ease-in-out;
        visibility: hidden;
        z-index: 10;
        &:before{
            position:absolute;
            bottom:-10px;
            left:90px;
            width:20px;
            height:10px;
            content:' ';
            border-left:10px solid transparent;
            border-right:10px solid transparent;
            border-top:10px solid #111;
        }
        p{
            margin-bottom:0.5rem;

        }
        strong{
            text-transform: uppercase;
            color:#ffe90a;
        }
        span{
            margin-right:0.3rem;
        }
    }
}




