@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

$font-family-pip-main: "acumin-pro", Helvetica, Arial, sans-serif;
$font-family-nav: 'Roboto Condensed', sans-serif;
$font-family-pip-condensed: 'acumin-pro-condensed', Helvetica, Arial, sans-serif;

body {
    font-family: $font-family-pip-main;
    font-size: 1em;
}
.text-color-primary{
    color:#cd1b00;
}
.text-color-black{
    color:#000;
}
.text-color-white{
    color:#fff;
}
h1 {
    // font-family: 'Oswald', Arial, Helvetica, sans-serif;
    font-family:$font-family-pip-main;
    text-transform: uppercase;
    font-size: 5rem;
    font-weight: bold;
    line-height: 1;
}

h2 {
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
}

h3 {
    font-family: $font-family-pip-main;
    font-weight: 500;
    text-transform: uppercase;
}

a {
    font-family: $font-family-pip-main;
    color: $primary;
    &:hover {
        text-decoration: none;
        color: $primary;
    }
}

ul{
    padding:0;
    margin:0;
}

figure{
    padding:0;
    margin:0;
}

.blockquote {
    background: grey;
    border-radius: 10px;
    color: whitesmoke;
}
