button {
    background: #111;
    color: whitesmoke;
    border: 1px solid whitesmoke;
    font-family: $font-family-pip-main !important;
    text-transform: uppercase;
    padding: .3rem .7rem;
    font-size: .7rem !important;
    transition: all .3s;
    &:hover {
        background: lighten(#111, 10%);
    }


}

.button-tran {
    background: transparent;
}
.btn{
    border-radius:0 !important;
     text-transform:uppercase;
     font-size: .9rem !important;
 }
 .btn-primary{
     background-color: $primary !important;
     border-color:transparent !important;
 }
#nav-search {
    button {
        font-size: .7rem;
    }
}
