
main {
    min-height: 77vh;
    background: white;
    font-family: $font-family-pip-main;
    position:relative;


    .industry {
        background-position: center;
        min-width: 25%;
        height: 80vh;
        position: relative;
        transition: all .4s ease-out;
        background-color: grey;
        .industry-text {
            background: transparent;
            color: white;
            padding: 2em;
            z-index: 2;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 991px){
                background: rgba(0,0,0,.6);
            }
            h3 {
                font-size: 3.5rem;
                font-family: 'Oswald', Arial, Helvetica, sans-serif;
                font-weight: 800;
                text-transform: uppercase;
            }

        }
        &:hover {
            cursor: pointer;
            .industry-text {
                background: rgba(0,0,0,.6);
            }
        }

        @media (max-width: 1168px){
            min-width: 50%;
            height: 40vh;
        }
    }

    #industries {
        min-height: 20Vh;
        flex-wrap: wrap;
        #automotive {

            background-repeat: no-repeat;
        }

    }
    #brands {
        display: flex;
        flex-wrap: wrap;
        background: $primary;
        color: white;
        padding: 2rem;
        .brands-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem 3rem;
            h4 {
                text-transform: uppercase;
                font-size: 1rem;
            }
        }
        .brands-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            flex-grow: 3;
            .brand {
                margin: 1em .5em;
                max-width: 150px;
                img {
                    max-width: 100%;
                }
            }
        }

    }
    .tag-line{
        background: $red;
        color:#ffffff;
        position:relative;
        z-index:2;
        h2{
            font-size:3rem;
        }
    }
}

.lines {
    background-image: url('/images/diagonal-lines.png');
    background-repeat: repeat-y;
    height: 90%;
    overflow-y: hidden;
}

#hero-slider {
    background: $secondary;
   .img-fluid {
       min-height: 250px;
       object-fit: cover;
   }
}




