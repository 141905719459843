#main-nav {
    font-family: $font-family-pip-main;
    font-weight: 500;
    font-size: 1.1rem;
    background: white;
    height: 75px;
    padding-top: 2em;
    @media screen and (max-width: 1199px){
        background: $primary;
        color: white;
        // height: 93px;
        justify-content: center;
    }

    .navbar-brand {
        justify-self: flex-start;
        font-weight: normal;
        z-index: 20;
        transform: translateY(-5px);
        margin: 3.3rem;
        @media screen and (max-width: 1199px){
            transform: translateY(-87px);
        }
        img {
            width: 125px;
            margin: 0 auto;
            @media screen and (max-width: 1199px){
                width: 110px;
            }

        }
    }
    .container {
        margin: 0 2em;
        min-width: 90%;

    }
    .navbar-nav {
        margin-left: 4em;
        @media screen and (max-width: 1275px){
            margin-left: 3em;
            .nav-item {
                a.nav-link {
                    font-size: 1.1rem;
                }
            }
        }
        @media screen and (max-width: 1175px){
            transform: translateX(-39px);
        }
        .nav-item {
            .nav-link {
                color: #111;
                text-transform: uppercase;
                font-family: $font-family-nav;
                font-size: 1.2rem;
                padding: 0 1vw;
                &:hover,
                &:active,
                &:focus {
                    color: #111;
                    outline: 0;
                }

            }
        }
        .dropdown-menu {
            border-bottom: 4px solid grey;
            font-family: $font-family-pip-condensed;
            .submenu{
                height:auto;
            }
            .maxHeight{
                height:100% !important;
            }
        }
    }

}
.pip-site-header {
    background: #000;
    display: flex;
    font-style: italic;
    font-weight: 600;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height:25px;
    @media screen and (max-width: 1200px) { display:none;}
    .pip-top-bar {
        font-family: $font-family-pip-main;
        font-size: .7rem;
        font-weight: bold;
        background: $primary;
        right: 5px;
        padding: 0 .5em;
        position: absolute;
        text-align: center;
        top: -1px;
        z-index: 5;
        a {

            display: flex;
            align-items: center;
            color: #efefef;
            img {
                display: inline-block;
                position: absolute;
                top: 6px;
                right: 49px;
                width: 187px;
            }
        }
        span {
            display: inline-block;
            text-transform: uppercase;
        }

    }
}
.pip-login-bar{
    font-family: $font-family-pip-main;
     border-left: 1px solid #ccc;
    background: #333;
    text-align: center;
    line-height: 1;
    .dropdown-toggle{
        color:#ffffff;
        cursor:pointer;
        &:after{
            display:none;
        }
        .login{
            font-size: 0.8rem;
            text-transform: uppercase;
            font-weight:400;
        }
        .welcome{
            width:200px;
            text-align:left;
            small{
                font-size:0.6rem;
            }
            span{
                font-size: 0.8rem;

            }
        }
    }
    .dropdown-menu{
        margin:0;
        right:0;
        border:none;
    }
    .login-block{
        padding:1rem ;
        font-size:0.9rem;
        background-color: #2b2b2b;
        color: #efefef;
        img{
            width:60%;
        }
        label{
            text-transform:uppercase;
            margin-bottom:0.2em;
            font-weight:600;

        }
        form{
            .form-control{
                background-color:transparent;
                border-radius:0;
                line-height:1;
                color:#ffffff;
                height: calc(1.6em + 0.4rem + 2px);
            }
            small{
                a{
                    font-size:0.6rem;
                   font-family: $font-family-pip-main;

                }
            }
        }
        .btn{
            line-height:1.2;
        }

    }
    .dashboard-menu{
        font-family: $font-family-pip-condensed;
        list-style:none;
        margin:0;
        padding:0;
        background-color: #2b2b2b;
        color: #efefef;
        li{
            a{
                display: block;
                padding: 0.6em 1em;
                color:#efefef;
                font-size:1rem;
                font-family:$font-family-pip-condensed;
                &:before{
                    display:none !important;
                }
            }
        }
    }

}

.pip-main-nav{
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 999;
}

.logo-bg {
    background: $primary;
    height: 90px;
    position: absolute;
    top: 0;
    left: 0;
    width: 255px;
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        top: 4px;
        right: -22px;
        width: 44px;
        height: 86px;
        background: $primary;
        transform: skew(-27deg, 0deg);
    }
}

.modal-backdrop{
    z-index:10;
}

@media (max-width: 576px){
    .modal-dialog.modal-dialog-slideout {
        width: 80%;
    }
}
.modal-dialog-slideout {
    min-height: 100%;
    margin: 0 auto 0 0 ;
    background: #fff;
}
.modal.fade .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(-100%,0);
    transform: translate(-100%,0);
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
    flex-flow: column;}
.modal-dialog-slideout .modal-content{
    border: 0;
}
