// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'utilities/variables';

// Set Brand Colors Here
$primary: #cd1b00;
$secondary: #3f3f3f;
$tertiary: #111;
$success:forestgreen;

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'base/bootstrap-overrides';
@import 'base/typography';
@import 'base/buttons';
@import 'base/forms';

@import 'components/header';
@import 'components/footer';
@import 'components/pagination';
@import 'components/mobileNav';
@import 'components/smart-filter';

@import 'layout/main';

@import 'pages/category';
@import 'pages/dashboard';
@import 'pages/literature';
@import 'pages/product-detail';
@import 'pages/user-list';
@import 'pages/login';
@import 'pages/product';





